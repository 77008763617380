import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { linearGradient } from "../components/styles"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const MarkerHeader = styled.h3`
  display: inline;
  border-radius: 1em 0 1em 0;
  margin-bottom: 10px;
  background-image: ${linearGradient};
`

const isBrowser = () => typeof window !== "undefined"
const isAmber = isBrowser() && window.localStorage.getItem('isAmber');

const GoodStuffPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Good Stuff"/>
      {isAmber ?
        <Content>
          <h1>Good Stuff</h1>

          <h2>September 6, 2022</h2>
          <p>I wanted to skip kickboxing because I did 20 miles on a backpacking weekend. But I'm reading <i>Can't Hurt Me</i> and that dude can run on broken legs, so I can probably go to combat after a weekend of hiking. A girl in the class came up and told me she would have walked out if not for me, that I was "inspiring" and that she wanted me on her side in a fight. That girl was young and rail-thin.</p>
          <hr></hr>

          <h2>Aug 17, 2022</h2>
          <p>Aaron scheduled time with me to talk about stress on the team in general. He said he "looked up to me".</p>
          <hr></hr>
        </Content> :
        <div>You aren't Amber, so you don't get to read her good stuff page.</div>
      }
    </Layout>
  )
}

export default GoodStuffPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            path
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`
